<!--审核状态页面-->
<template>
  <div class="review-box">
    <div class="reviewStatus">
      <img src="@/assets/images/h5-icon/pass.png">
      <span>购买成功</span>
      <p>{{tipList.paySuccessPrompt}}</p>
      <a @click="toOrderDetail">查看课程详情</a>
    </div>
  </div>

</template>
<script>
import { getOrderDetail, getLessonGroupTips } from '@/api/api'
export default {
  name:'review',
  data() {
    return {
      orderId: '',
      orderDetail: null,
      tipList:{}, // 审核提示语
    }
  },
  methods:{
    timeJump() {
      window.setTimeout(() => {
        this.toOrderDetail()
      }, 5000)
    },
    toOrderDetail() {
      this.$router.push({path: `/course/${this.orderDetail.courseGroupId}`})
    },
    async getOrderDetail() {
      let params = {
        orderId: this.orderId
      }
      let response = await getOrderDetail(params);
      if(+response.returnCode !== 10001) {
        this.$toast(response.returnMsg)
        return false
      }
      this.orderDetail = response.data
      this.getLessonGroupTips(this.orderDetail.courseGroupId)
      this.timeJump()
    },
    async getLessonGroupTips(courseGroupId) {
      let response = await getLessonGroupTips({courseGroupId})
      this.tipList = response.data
    }
  },
  mounted() {
    this.orderId = this.$route.query.orderId
    this.getOrderDetail()
  },
}
</script>
<style scoped>
.review-box{
  background:#F3F3F3;
}
.reviewStatus{
  width:100%;
  background:#fff;
  text-align: center;
}
.reviewStatus img{
  display: block;
  width:1.2rem;
  height:1.2rem;
  margin:0.3rem auto;
}
.reviewStatus span{
  display: block;
  font-size: 14px;
  font-weight: bold;
  height:0.6rem;
  line-height: 0.6rem;
}
.reviewStatus p{
  font-size: 12px;
  height:0.4rem;
  line-height: 0.4rem;
}
.reviewStatus a{
  display: block;
  font-weight: bold;
  text-decoration: underline;
  color: #2E8DF4;
  padding:0.2rem 0;
  font-size: 14px;
}
.payWay{
  width:94%;
  padding:0 3%;
  margin:0.25rem 0;
  background: #fff;
}
.payWay .title{
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  line-height: 0.5rem;
  padding:0.2rem 0;
  border-bottom:1px #f2f2f5 solid;
}
.payWay .infoList{
  display: flex;
  font-size: 14px;
  color:#333;
  line-height: 0.8rem;
  text-align: left;
  align-items: center;
}
.payWay .infoList img{
  width:0.49rem;
  height: 0.4rem;
  margin-right: 0.2rem;
}
.payWay .infoList .way{
  flex-grow:2
}
.payWay .money{
  font-size: 16px;
  color:#333;
  line-height: 1rem;
  text-align: left;
  font-weight: bold;
}
.payWay .money .red{
  color:#FF3F47;
}
.paybox{
  width:94%;
  padding:0 3%;
  background:#fff;
  margin-top:0.25rem;
  position: fixed;
  bottom:0;
  height:2rem;
}
.paybox p{
  font-size: 14px;
  color:#FF9540;
  text-align: center;
  height: 0.5rem;
  padding:0.2rem 0;
}
.paybox .payBtn{
  width:100%;
  height:0.78rem;
  line-height: 0.78rem;
  text-align: center;
  font-weight: bold;
  background: #FF3F47;
  color:#ffffff;
  border-radius: 10px;
  padding: 0;
  margin:0;
}
</style>